<template>
    <div>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="110px" size="mini">
                <div class="transition-box">
                    <el-form-item label="学员编号" prop="isAbandon">
                        <el-input v-model="params.id" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员姓名" prop="isAbandon">
                        <el-input v-model="params.id" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员备注" prop="isAbandon">
                        <el-input v-model="params.id" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="学员类型" prop="isAbandon">
                        <el-select v-model="params.id">
                            <el-option value=""></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="下单手机号" prop="isAbandon">
                        <el-input v-model="params.id" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="商品名称" prop="isAbandon">
                        <el-input v-model="params.id" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="下单渠道" prop="isAbandon">
                        <el-select v-model="params.id">
                            <el-option value=""></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分配状态" prop="isAbandon">
                        <el-select v-model="params.id">
                            <el-option value=""></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="购买时间" prop="isAbandon">
                        <el-date-picker
                                v-model="params.id"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </div>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button-group>
                    <el-button type="primary" size="mini">分配</el-button>
                    <el-button type="primary" size="mini">导入</el-button>
                    <el-button type="primary" size="mini">排版信息</el-button>
                </el-button-group>
            </el-col>
            <el-col :span="6">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading">
            <el-table-column align="center" label="主键" prop="id"></el-table-column>
            <el-table-column align="center" label="学员姓名" prop="id"></el-table-column>
            <el-table-column align="center" label="学员备注" prop="id"></el-table-column>
            <el-table-column align="center" label="学员类型" prop="id"></el-table-column>
            <el-table-column align="center" label="成单销售" prop="id"></el-table-column>
            <el-table-column align="center" label="成单类型" prop="id"></el-table-column>
            <el-table-column align="center" label="商品名称" prop="id"></el-table-column>
            <el-table-column align="center" label="成单价格" prop="id"></el-table-column>
            <el-table-column align="center" label="下单手机号" prop="id"></el-table-column>
            <el-table-column align="center" label="下单渠道" prop="id"></el-table-column>
            <el-table-column align="center" label="购买时间" prop="id"></el-table-column>
            <el-table-column align="center" label="订单编号" prop="id"></el-table-column>
            <el-table-column align="center" label="服务号" prop="id"></el-table-column>
        </el-table>

        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
import * as srecordApi from "@/api/waiter/waiterServicesRecord.js"

export default {
    name: "WaiterServicesRecord",
    data() {
        return {
            params: {
                current: 1,
                size: 10
            },
            loading: false,
            dataList: [],
            total: 0,
        }
    },
    mounted() {

    },
    methods: {}
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>